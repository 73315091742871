








































import { defineComponent } from '@vue/composition-api';
import errorMappings from '@/libs/errorMappings';
import commonUtils from '@/utils/common.utils';
import TrackingUtils from '@/utils/tracking/tracking.utils';
import * as bfNewsletterApi from '@/api/bfNewsletter.api';
import * as bfSSOApi from '@/api/bfSSO.api';
import alert from '@/components/alert.component.vue';
import { mapGroupNameToElaineGroupId } from './newsletter/elaine.utils';

export default defineComponent({
  name: 'newsletter-teaser-component',
  components: {
    alert,
  },
  props: {
    group: String,
    title: String,
    bgImageUrl: String,
    bgImageTabletUrl: String,
    bgImageDesktopUrl: String,
    defaultLayout: Boolean,
    colorText: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      user: {
        email: '',
      },
      success: false as any,
      error: false as any,
      legalVersion: null as String | null,
      legalHtml: null as String | null,
    };
  },
  computed: {
    cssVars(): any {
      return {
        '--bg-img': this.bgImageUrl,
        '--bg-img-tablet': this.bgImageTabletUrl,
        '--bg-img-desktop': this.bgImageDesktopUrl,
        '--color-text': this.colorText,
      };
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'user.email': function () {
      this.error = '';
      this.success = '';
    },
  },
  async mounted() {
    await this.getLegalData();
    TrackingUtils.trackEvent({
      event: 'register',
      eventCategory: 'register',
      eventAction: 'impression',
      eventLabel: 'newsletter-block',
      eventValue: undefined,
      registerType: this.group,
    });
  },
  methods: {
    async getLegalData() {
      const legalData = await bfSSOApi.getNewsletterLegalTextPublic();
      this.legalVersion = legalData.version;
      this.legalHtml = legalData.checkboxtext_html;
    },
    async submit() {
      this.error = commonUtils.verifyEmail(this.user.email);
      if (this.error !== '' || this.legalVersion === null) {
        this.status = '';
      } else {
        const data = {
          groupId: mapGroupNameToElaineGroupId(this.group),
          email: this.user.email,
          subscriptionLds: `nl_p1_bf_nleinwilligung-${this.legalVersion}`,
          referrer: `newsletter-block-${this.group || 'default'}`,
        };

        TrackingUtils.trackEvent({
          event: 'register',
          eventCategory: 'register',
          eventAction: 'success',
          eventLabel: 'newsletter-block',
          eventValue: undefined,
          registerType: this.group,
        });
        try {
          await bfNewsletterApi.subscribeBfNewsletterPublicByGroupId(data);
          this.success = 'Anmeldung erfolgreich.';
        } catch (e) {
          console.error(e);
          this.error = errorMappings.defaultServerError;
        }
      }
    },
  },
});
